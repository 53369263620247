import SysColorPicker from './SysColorPicker.js'

/**
 * Creates a custom legend component
 * @class
 */
export default class SysLegend{

    #legendContainer
    #ref
    #dataset
    #transitionTime
    #colorPicker

    /**
     * 
     * @param {Object} params 
     *  ref {Object} - graph object
     *  transitionTime {Number} - expressed in milliseconds, used to change length in transition effects
     */
    constructor(params){
  
      this.#legendContainer = null;
      this.#ref = params.ref;
      this.#transitionTime = params.transitionTime || 500; 
      this.#dataset = null;  
      // init color picker
      this.#colorPicker = new SysColorPicker({
        ref: this.#ref
      });

      this.init();
  
    }

    /**
     * Creates a legend container
     */
    init(){
      this.#legendContainer = this.#ref.getSVG()
        .append("g").attr("id", "legendContainer");
    }

    /**
     * Deletes all the content
     */
    clearAll(){
      this.#legendContainer.selectAll("*").transition().duration(this.#transitionTime).style("opacity", 0).remove();
    }

    /**
     * Gets an updated dataset from reference
     */
    updateReferenceData(){
      this.#dataset = this.#ref.getDataset();  
    }

    /**
     * Draws legend
     */
    drawLegend(){
      
      let delta = 0;
      let labelLength = 150
      let circleDiameter = 10;
      let margin = 0;
      let spacer = 5;
      let maxLength = 16;
      let xMargin = 0;
      let yMargin = -35;
  
      this.updateReferenceData();

      // add root name
      if(this.#ref.getAxisMode() === 3){
  
        delta = labelLength;
  
        this.#legendContainer
          .append('g')
          .append("text")
            .style("opacity", 0)
            .attr('class', "sys-lc-label")
            .attr('x', xMargin)
            .attr('y', yMargin)
            .text( this.#dataset[0].rootName )
            .style("fill", "gray" )
            .transition().duration(this.#transitionTime).style("opacity", 1);
      }
  
  
      // Add a legend (interactive)
      let labelContainer = this.#legendContainer
        .selectAll("legendContainer")
        .data(this.#dataset)
        .enter()
          .append('g')
          //.attr("class", d => `${d.groupName}`)
  
      // circle
      labelContainer
          .append("circle")
            .attr("class", "sys-lc-circle clickable")
            .attr("cx", (d,i) => delta + margin + i*labelLength + spacer)
            .attr("cy", yMargin - 5)
            .attr("r", 5)
            .attr("data-group", d => d.groupName )
            .attr("stroke", d => this.#ref.getGroupColor(d.groupName) )
            .attr("fill", d => this.#ref.getGroupColor(d.groupName) )
            .on("click", (e) => {
              this.#colorPicker.open(e);
            });
      
      // text
      labelContainer
          .append("text")
            .attr('class', "sys-lc-label clickable")
            .attr('x', (d,i) => delta + margin + circleDiameter + spacer + i*labelLength)
            .attr('y', yMargin)
            .attr("data-content", d => d.groupName)
            .text( d => {
              return d.groupName.length > maxLength ? d.groupName.substring(0, maxLength - 1) + "..." : d.groupName;
            } )
            .style("fill", d => this.#ref.getGroupColor(d.groupName) )
          .on("click", d => {
  
            let groupName = d.target.attributes['data-content'].value;
  
            // is the element currently visible ?
            let currentOpacity = this.#ref.getSVG().selectAll(`.${groupName}`).style("opacity");
            // Change the opacity: from 0 to 1 or from 1 to 0
            this.#ref.getSVG().selectAll(`.${groupName}`).transition().style("opacity", currentOpacity == 1 ? 0:1);
          })
          .append("title")
          .text( d => d.groupName )
  
      labelContainer
        .style("opacity", 0)
        .transition().duration(this.#transitionTime).style("opacity", 1);      
    }

    /**
     * Updates group color
     * @param {String} group 
     * @param {String} color 
     */
    updateColor(group, color){
    
      this.#legendContainer.selectAll(`circle[data-group=${group}]`)
        .transition().duration(this.#transitionTime)
        .attr("stroke", color )
        .attr("fill", color)
  
      this.#legendContainer.selectAll(`text[data-content=${group}]`)
        .transition().duration(this.#transitionTime)
        .attr("style", () => "fill:" + color )
  
    }
}