<template>
  <v-row justify="center">
     <v-dialog 
      :value="show" 
      overlay-opacity="0.4"
      overlay-color="#ffffff"
      persistent
      width="800"
    >
       <v-card flat>
       <v-overlay :absolute="true" :value="loading">
            <v-row align="center" justify="center" >
            <v-col align="center"  >
              <v-progress-circular
                indeterminate
                color="info"
                :size="350"
              >
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-overlay>
        <v-card-title class="text-h5 blue darken-4 white--text">
          <v-toolbar-title>New widget</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="onCancel()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="widget-container">
            <div @click="onSave(item.id)" v-for="(item, i) in types" :key="i" class="widget-item">
              <span class="widget-label">{{item.name}}</span>
              <img :src="imgs[item.img]" width="120px"/>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import _ensembles_graph from "../../assets/ensembles_graph.png";
import _params_graph from "../../assets/params_graph.png";
import _table from "../../assets/table.png";

export default {
  name: 'NewWidget',
   props: [
    'show',
    'onSave',
    'onCancel',
  ],
  data () {    
    return {
      loading: false,
      types: undefined,
      imgs:{
        ensembles_graph: _ensembles_graph,
        params_graph: _params_graph,
        table: _table
      }
    };
  },
  watch: {
    show: function ( newVal ) {
      if(newVal && !this.types){
        this.loadType()
      }
    }
  },
   methods: { 
    async loadType(){
      this.loading = true
      const d = await  this.$http.call({
        url: '/widget/types', 
        method: 'get',
        hideMsg: true
      });
      this.types = d.data;
      this.loading = false
    },
  },
}
</script>


<style scoped>

.widget-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 500px;
  margin-top: 20px;
}

.widget-item {
  flex: 0 1 calc(30% - 15px);
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

.widget-label {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
