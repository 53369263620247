<template>
  <v-app>

   <v-snackbar 
      :color="color" 
      v-model="show" 
      :timeout="timeout" 
      top 
      >
      {{ text }} 
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="show=false" style="pointer:cursor">mdi-close-circle-outline</v-icon>
      </template> 
    </v-snackbar>

    <!-- LOADING TENANT  --->
    <div v-if="!tenant" >

      <div class="loginMain">
        <LoadingTenant />
      </div>
    </div>

    <!-- MAIN APP --->
    <div v-if="loggedIn">

      <Header :user="user"/>

      <div class="v-menu__content--fixed menuContainer" role="menu">
        <Menu />
      </div>
      
      <v-main class="mainContainer">
        <v-overlay :value="isLoading">
          <v-row align="center" justify="center" >
          <v-col align="center"  >
            <v-progress-circular
              indeterminate
              color="info"
              :size="350"
            >
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-overlay>
        <router-view></router-view>
      </v-main>

    </div>
    
    <!-- LOGIN PAGE --->
    <div v-else>
      <div class="loginMain">
        <Login />
      </div>
    </div>

  </v-app>
</template>

<style>
  @import './assets/styles/shbox.css';
</style>


<script>
import { mapState } from 'vuex'
import Menu from '@/components/Menu';
import Header from '@/components/Header';
import Login from '@/components/login/LoginPage';
import LoadingTenant from '@/components/login/LoadingTenant.vue';

export default {
  name: 'App',
  components: {
    Menu,
    Header,
    Login,
    LoadingTenant,
  },
  computed: {
    ...mapState(['user','loggedIn','breadcrumbs','snackbar', 'isLoading', 'tenant']),
  },
  data () {
    return {
      timeout: 5000,      
      show: false,
      text: this.$store.state.snackbar.text,
      color: this.$store.state.snackbar.color, 
    }
  },
  created: function () {
    //Imposta il tema dark se configurato dall'utente
    this.$vuetify.theme.dark = (this.$store.state.theme === 'dark');

    this.$store.dispatch('reset_snackbar');
    this.$store.dispatch('_setLoading', false);
    
    this.$store.watch(state => state.snackbar.text, () => {

      const msg = this.$store.state.snackbar.text
      this.show = false;
      if (msg !== '') {
        this.show = true;
        this.text = msg;
        this.color = this.$store.state.snackbar.color;
        setTimeout(() => { this.$store.dispatch('reset_snackbar');},this.timeout);        
      }
    }) 
  }
};
</script>