<template>
  <v-container class="px-0">
    <div class="buttonContainer">
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small @click="() => showNew = true" color="primary" v-bind="attrs" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New widget</span>
      </v-tooltip>
    </div>
    <v-row class="my-5 d-flex align-center justify-space-between">
      <v-col cols="3">
        <v-select v-model="structureId" :items="itemsStructure" label="Structure" dense item-text="label"
          item-value="structureId" @change="changeFilters" :rules="[rules.required]">
        </v-select>
      </v-col>

      <v-col cols="3">
        <Date-picker id="dateFrom" :rules="[rules.required]" :timestamp="dateFrom" label="Date From"
          @update:timestamp="changeFilters" />
      </v-col>

      <v-col cols="3">
        <Date-picker id="dateTo" :rules="[rules.required]" :timestamp="dateTo" label="Date To"
          @update:timestamp="changeFilters" />
      </v-col>

      <!--
        <v-col cols="3" style="text-align:right">
          <v-btn color="success">
            <v-icon>mdi-export</v-icon>Export all
          </v-btn>
        </v-col>
        -->
    </v-row>


    <WidgetGrid :items="widgets" :deleteWidget="deleteWidget" />
    <NewWidget :show="showNew" :onSave="addWidget" :onCancel="() => showNew = false" />
  </v-container>
</template>

<script>

import WidgetGrid from '@/components/graphs/WidgetGrid';
import NewWidget from '@/components/graphs/NewWidget';
import DatePicker from '@/components/shared/DatePicker';
import { mapState } from 'vuex';

export default {
  name: 'WidgetsGraphs',
  components: {
    WidgetGrid,
    DatePicker,
    NewWidget
  },
  computed: {
    rules() {
      return this.$script.formRules
    },
    ...mapState([
      'user',
      'plantId'
    ]),
  },
  data() {
    return {
      dashboard: null,
      itemsStructure: [],
      structureId: null,
      loading: false,
      dateTo: null,
      dateFrom: null,
      breadcrumbsItems: [
        { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home' },
        { href: '/widgets_graphs', disabled: true, text: this.$t("widgets_graphs_breadcrumbs").toUpperCase() }
      ],
      widgets: [],
      showNew: false,
      resizeTimeout: null
    }
  },
  watch: {
    plantId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.load(true)
      }
    }
  },
  created: function () {

    window.addEventListener('resize', this.handleResize);

    this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);

    let _dateFrom = this.$date.now();
    let _dateTo = this.$date.now().plus({ days: 1 });

    // can manage both formats: 'yyyy-MM-dd' and milliseconds
    if (this.user.sessionParams) {
      if (this.user.sessionParams.dateFrom) {
        // string format yyyy-MM-dd
        if (!isNaN()) {
          _dateFrom = this.$date.fromJSDate(new Date(this.user.sessionParams.dateFrom));
          // timestamp format
        } else {
          _dateFrom = this.$date.fromJSDate(new Date(this.user.sessionParams.dateFrom * 1));
        }
      }

      if (this.user.sessionParams.dateTo) {
        // string format yyyy-MM-dd
        if (!isNaN()) {
          _dateTo = this.$date.fromJSDate(new Date(this.user.sessionParams.dateTo));
          // timestamp format
        } else {
          _dateTo = this.$date.fromJSDate(new Date(this.user.sessionParams.dateTo * 1));
        }
      }
    }

    this.dateFrom = (_dateFrom.toMillis());
    this.dateTo = (_dateTo.toMillis());


    this.load()

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this.resizeTimeout);
  },
  methods: {
    async load(forceFirstRecord = false) {
      await this.loadStructure(forceFirstRecord)
    },

    async loadStructure(forceFirstRecord = false) {
      this.itemsStructure = await this.$store.dispatch('_getStructures');

      if (!forceFirstRecord) {
        if (!this.structureId) {
          if (this.user?.sessionParams?.structureId) {
            this.structureId = this.user?.sessionParams?.structureId;
          } else {
            if (this.itemsStructure.length > 0) {
              this.structureId = this.itemsStructure[0].structureId;
            }
          }
        }
      } else {
        if (this.itemsStructure.length > 0) {
          this.structureId = this.itemsStructure[0].structureId;
        }
      }

      this.loadData()
    },

    async loadData() {

      // added filter on parameters, because
      // it's called twice from changeFilters because of async behaviour
      // before structureId is available
      // console.log(this.user.id, this.structureId);
      if (this.user.id !== null && this.structureId !== null) {

        this.$store.dispatch('_setLoading', true);

        try {
          const dashboards = await this.$store.dispatch('_getDashboards', {
            structureId: this.structureId,
            userId: this.user.id
          });
          this.dashboard = dashboards[0];

          // if dashboard is not credated yet, add it first
          if (this.dashboard)
            this.loadWidgets();
          else
            this.createDashboard();
        } catch (error) {
          this.$store.dispatch('_setLoading', false);
        }

        this.$store.dispatch('_setLoading', false);
      }
    },

    async changeFilters(event) {

      // update date values
      if (event.id === "dateFrom" && this.dateFrom !== event.timestamp) {
        this.dateFrom = event.timestamp;
      }

      if (event.id === "dateTo" && this.dateTo !== event.timestamp) {
        this.dateTo = event.timestamp;
      }

      this.saveUserSettings()
      this.loadData()
    },

    async saveUserSettings() {
      this.$store.dispatch('_updateUser', {
        data: {
          ...this.user,
          sessionParams: {
            ...this.user.sessionParams || {},
            structureId: this.structureId,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo
          }
        },
        hideMsg: true
      })
    },

    async createDashboard() {

      let structure = this.itemsStructure.find(x => x.structureId === this.structureId)

      const data = {
        structureId: this.structureId,
        label: `${structure.label}_${this.user.user_name}`
      }
      await this.$store.dispatch('_createDashboard', data);

      this.loadData()
    },

    async loadWidgets() {
      this.widgets = await this.$store.dispatch('_getWidgets', { dashboardId: this.dashboard.dashboardId });
    },

    async addWidget(graphWidgetTypeId) {
      this.showNew = false
      const data = {
        dashboardId: this.dashboard.dashboardId,
        graphWidgetTypeId,
        x: 0,
        y: 0,
        height: 4,
        width: 6,
        query: {},
        filter: {},
        params: {}
      }

      await this.$store.dispatch('_addWidget', data);

      this.loadData()
    },

    async deleteWidget(widgetId) {
      await this.$store.dispatch('_deleteWidget', widgetId);
      this.loadWidgets();
    },
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        console.log('resize');
        this.loadWidgets();
      }, 200); // Ritardo di 200 ms
    },
  }
}
</script>

<style scoped>
.buttonContainer {
  position: fixed;
  top: 76px;
  right: 10px;
  width: 64px;
  text-align: center;
  line-height: 48px;
}
</style>
