<template>
  <v-container class="px-0">
    
    <Filters :onFilter="loadList"/>
    <v-data-table
      :headers="headers" 
      :items="dataset" 
      class="tableRow"
      :loading="loading"
      loading-text="Loading... Please wait"
     >
       <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-bind="item" @click="editItem(item)" text icon color="primary">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        &nbsp;
        <v-btn v-bind="item" @click="deleteItem(item)" text icon color="error">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <SecondaryMenu routeFrom="/ensembles/list" :onClick="newItem"/>
     <Confirm 
      :show="showDeleteConfirm"
      message="Do you really want to delete this item?"
      title="Delete Ensemble"
      :onConfirm="onConfirmDelete"
      :onCancel="()=> showDeleteConfirm = false"
    />
     <Ensemble-edit 
      :show="showEdit"
      :onSave="save"
      :onCancel="()=> showEdit = false"
      :selectedItem="selectedItem"
    />

  </v-container>
</template>


<script>
import Filters from '@/components/shared/Filters'
import SecondaryMenu from '@/components/shared/SecondaryMenu'
import Confirm from '@/components/shared/Confirm';
import EnsembleEdit from '@/components/ensembles/EnsembleEdit';
import { mapState } from 'vuex';

export default {
  name: 'EnsembleList',
  components:{
      Filters,
      SecondaryMenu,
      Confirm,
      EnsembleEdit
  },
  data () {
    return {
      breadcrumbsItems: [
        { href: '/home', disabled: false, text: this.$t("home").toUpperCase(), to: '/home'},
        { href: '/ensembles', disabled: true, text: this.$t("ensembles").toUpperCase()}
      ],
      loading: false,
      headers: [
        { text: 'Name', value: 'label', align: 'start' },
        { text: 'Algorithm', value: 'algorithm' },
        { text: 'Area', value: 'area' },
        { text: 'Structure', value: 'structure' },
        { text: 'Element', value: 'element' },
        { text: 'Actions', value: 'actions' }
      ],
      dataset: [],
      showDeleteConfirm: false,
      selectedItem: null,
      showEdit: false 
    };
  },
  computed: {
    ...mapState(['plantId'])
  },
  watch: {
    plantId(newVal, oldVal) {
      if(newVal !== oldVal){
        this.loadList()
      }
    },
  },
   methods: {       
    async loadList(params){
      const {area, element,structure} = params || {}
      this.loading = true;
      
      const filters = {
        areaId : element || structure ? undefined:  area?.areaId,
        structureId: element? undefined: structure?.structureId,
        elementId: element?.elementId,
      }
      
      // if array, use it, if object convert to array
      let _dataset = await this.$store.dispatch('_getEnsembles', filters);
      _dataset = Array.isArray(_dataset) ? _dataset : Object.values(_dataset)

      this.dataset = _dataset;
      
      this.loading = false;
    },
    editItem(item){
      this.selectedItem = item
      this.showEdit = true
    },
    deleteItem(item){
     this.showDeleteConfirm = true
     this.selectedItem = item
    },
    async onConfirmDelete(){
      this.showDeleteConfirm = false
      this.loading = true
      await this.$store.dispatch('_deleteEnsemble', {ensembleId: this.selectedItem.ensembleId});
      this.loading = false
      await this.loadList()
    },
    newItem(){
      this.selectedItem = {
      }
      this.showEdit = true
    },
    save(){
      this.showEdit = false
      this.loadList()
      this.selectedItem = null
    },
  },
  mounted: function() {
    this.$store.dispatch('_updateBreadcrumbs', this.breadcrumbsItems);
    if(this.$route.params.objectId){
    if(this.$route.params.objectType && this.$route.params.objectId){
      this.selectedItem = {
        objectId: parseInt(this.$route.params.objectId, 10),
        objectType: this.$route.params.objectType.toUpperCase()
      }
        this.showEdit = true
        this.$router.replace('/ensembles/list')
      }
    }
    this.loadList()
  }
}
</script>
